<template>
    <v-col class="my-0 py-0 px-0 px-md-10 pb-15">
        <!-- Alerta de información -->
        <v-col class="text-start mt-5 mb-3 px-0">
            <v-alert
                text
                dense
                color="#8061c2"
                border="left"
                class="pl-5"
            >
                <h1 class="title mt-1">Panel</h1>
                <p class="desc mt-0 mb-1">Gráficas y estadísticas detalladas de la plataforma.</p>
            </v-alert>
        </v-col>

        <!-- Totales y Gráfica -->
        <v-col cols="12" class="px-0 py-0">
            <v-row no-gutters>
                <v-col class="col-md-6 col-12 px-0 pr-md-2">
                    <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                        <h3 class="text-left title-dashboard">Totales</h3>
                        <p class="text-left desc-dashboard mb-8">Conteo de harmonys, usuarios y ganancias.</p>
                        <v-row no-gutters>
                            <div class="col-12 col-md-4 px-md-4 pl-md-0">
                                <div class="rounded-lg d-card text-left px-4 py-4">
                                    <v-icon size="30px" color="#f9947b" class="mt-3">mdi-vector-difference-ba</v-icon>
                                    <p class="mt-6 mb-0 title-card">{{ totalHarmonys }}</p>
                                    <p class="title-desc">Total Harmonys</p>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 px-md-4 px-md-5 px-0 my-3 my-md-0 pl-md-0">
                                <div class="rounded-lg d-card2 text-left px-4 py-4">
                                    <v-icon size="30px" color="#40d756" class="mt-3">mdi-link-variant</v-icon>
                                    <p class="mt-6 mb-0 title-card">{{ totalLinks }}</p>
                                    <p class="title-desc">Total Enlaces</p>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 px-md-4 pl-md-0">
                                <div class="rounded-lg d-card3 text-left px-4 py-4">
                                    <v-icon size="30px" color="#be82fe" class="mt-3">mdi-account-group</v-icon>
                                    <p class="mt-6 mb-0 title-card">{{ totalUsers }}</p>
                                    <p class="title-desc">Total Usuarios</p>
                                </div>
                            </div>
                        </v-row>
                    </v-card>
                </v-col>

                <!-- Gráfica de Vistas con Degradado -->
                <v-col class="col-md-6 col-12 px-0 pl-md-2 mt-5 mt-md-0">
                    <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                        <h3 class="text-left title-dashboard">Vistas del ({{new Date().getFullYear()}})</h3>
                        <apexchart
                            type="line"
                            height="212"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                    </v-card>
                </v-col>

                <v-col class="col-md-8 col-12 px-0 mt-5">
                    <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                        <h3 class="text-left title-dashboard">Vistas y clics totales</h3>
                        <p class="text-left desc-dashboard mb-8">
                            Suma de vistas, vistas únicas, clics y clics únicos.
                        </p>
                        <apexchart
                            type="bar"
                            height="300"
                            :options="groupedChartOptions"
                            :series="groupedChartSeries"
                        />
                    </v-card>
                </v-col>

                <v-col class="col-md-4 col-12 px-0 mt-md-5 mt-0 pt-0">
                    <v-col class="col-12 px-0 pl-md-4 mt-md-0 mt-5 pt-0 pb-2">
                        <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                            <h3 class="text-left title-dashboard">Vistas totales por país</h3>
                            <p class="text-left desc-dashboard mb-8">
                                Suma total de todas las vistas por países.
                            </p>
                            <!-- Contenedor para las etiquetas -->
                            <div class="chip-container px-3 py-3">
                                <v-chip
                                    v-for="(country, index) in countryData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    <img :src="country.flag" class="flags rounded-pill">&nbsp; {{ country.name }}: {{ formatNumber(country.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col class="col-12 px-0 pl-md-4 pb-2 mt-0">
                        <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                            <h3 class="text-left title-dashboard">Vistas totales por región</h3>
                            <p class="text-left desc-dashboard mb-8">
                                Suma total de todas las vistas por regiones.
                            </p>
                            <!-- Contenedor para las etiquetas de regiones -->
                            <div class="chip-container px-3 py-3">
                                <v-chip
                                    v-for="(region, index) in regionData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    {{ region.name }}: {{ formatNumber(region.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col class="col-12 px-0 pl-md-4 mt-0">
                        <v-card cols="12" class="rounded-lg px-5 py-4" elevation="2">
                            <h3 class="text-left title-dashboard">Vistas totales por ciudad</h3>
                            <p class="text-left desc-dashboard mb-8">
                                Suma total de todas las vistas por ciudades.
                            </p>
                            <!-- Contenedor para las etiquetas de ciudades -->
                            <div class="chip-container px-3 py-3">
                                <v-chip
                                    v-for="(city, index) in cityData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    {{ city.name }}: {{ formatNumber(city.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>
                </v-col>

            </v-row>
        </v-col>
    </v-col>
</template>

<script>
import axios from '@/axios.js';
import VueApexCharts from 'vue-apexcharts';
import  { getCountryFlagUrl } from "@/utils/countries.js";

export default {
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            totalHarmonys: 0,
            totalLinks: 0,
            totalUsers: 0,
            countryData: [],
            regionData: [],
            cityData: [],
            chartOptions: {
                chart: {
                    id: 'views-chart',
                    toolbar: { show: false },
                    animations: { enabled: true }
                },
                xaxis: {
                    categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                colors: ['#40D756', '#8061C2'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#40D756', '#8061C2'],
                        stops: [0, 100]
                    }
                },
                title: {
                    text: '',
                    align: 'center',
                }
            },
            chartSeries: [
                {
                    name: 'Vistas Totales',
                    data: []  // Datos para vistas totales
                },
                {
                    name: 'Vistas Únicas',
                    data: []  // Datos para vistas únicas
                }
            ],
            // Opciones de la gráfica de barras agrupadas
            groupedChartOptions: {
                chart: {
                    type: 'bar',
                    stacked: false,
                    toolbar: { show: false },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                        barHeight: '70%',
                    },
                },
                colors: ['#40D756', '#8061C2', '#f9947b', '#be82fe'],
                xaxis: {
                    categories: ['Vistas', 'Clics'],
                },
                title: {
                    text: '',
                    align: 'center',
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    position: 'top',
                },
            },
            groupedChartSeries: [
                {
                    name: 'Totales',
                    data: [0, 0],  // Vistas y Clics totales
                },
                {
                    name: 'Únicos',
                    data: [0, 0],  // Vistas y Clics únicos
                },
            ]
        };
    },
    created() {
        this.fetchStatistics();
        this.fetchTotalStatistics();
    },
    methods: {
        formatNumber(value) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(value);
        },

        async fetchTotalStatistics() {
            try {
                const response = await axios.get('/admin/get-statistics-total');
                const data = response.data;

                this.groupedChartSeries = [
                    {
                        name: 'Totales',
                        data: [data.total_views, data.total_clicks],  // Vistas totales, Clics totales
                    },
                    {
                        name: 'Únicos',
                        data: [data.unique_views, data.unique_clicks],  // Vistas únicas, Clics únicos
                    }
                ];

                this.countryData = data.visits_by_country.map(item => ({
                    name: item.country,
                    total: item.total,
                    flag: getCountryFlagUrl(item.country)
                }));

                this.regionData = data.visits_by_region.map(item => ({
                    name: item.region,
                    total: item.total
                }));

                this.cityData = data.visits_by_city.map(item => ({
                    name: item.city,
                    total: item.total
                }));

            } catch (error) {
                console.error('Error fetching total statistics:', error);
            }
        },

        async fetchStatistics() {
            try {
                const response = await axios.get('/admin/get-statistics');
                const data = response.data;

                this.totalHarmonys = data.total_sites || 0;
                this.totalLinks = data.total_links || 0;
                this.totalUsers = data.total_users || 0;

                // Actualizar datos de la gráfica
                this.chartSeries = [
                    {
                        name: 'Vistas Totales',
                        data: data.views_per_month || []
                    },
                    {
                        name: 'Vistas Únicas',
                        data: data.unique_views_per_month || []
                    }
                ];
            } catch (error) {
                console.error('Error fetching statistics:', error);
            }
        }
    }
};
</script>

<style scoped>

.flags {
    width: 21px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;  /* Permite que se dividan en múltiples filas */
    max-height: 100px; /* Ajusta esta altura si es necesario */
    overflow-x: auto;  /* Scroll horizontal */
    background-color: #f4e8ff;
    border-radius: 12px;
}

.title-desc {
    font-size: 15px;
    color: #676f7b;
}

.title-card {
    color: #616181;
    font-weight: 400;
    font-size: 26px;
}

.d-card {
    background-color: #fff4de;
}

.d-card2 {
    background-color: #dcfce7;
}

.d-card3 {
    background-color: #f4e8ff;
}

.title-dashboard {
    color: #8061c2;
    font-weight: 500;
}

.desc-dashboard {
    color: gray;
    font-size: 14px;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
